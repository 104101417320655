@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700;900&family=Poppins:wght@100;300;500;700;900&display=swap');

/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 300;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.editor-toolbar {
  background-color: #39435C;
  border: 1px solid #39435C;
}

.editor {
    background-color: rgb(59, 59, 59);
    border: 1px solid #1F2A36;
    border-radius: 6px;
    color: #fff;
    padding: 1rem;
    height: fit-content;
    margin-bottom: 3rem;
}

.editor-toolbar {
  display: flex;
  justify-content: space-around;
}

.CodeMirror.CodeMirror-wrap {
  background: #1F2A36;
  color: #fff;
  border: 1px solid rgb(107 121 142);
  border-radius: 4px;
}

pre.CodeMirror-line::selection {
  background: transparent;
}

span.CodeMirror-selectedtext {
  color: black;
}

.CodeMirror.CodeMirror-wrap.CodeMirror-focused {
  outline: none;
  border: 1px solid rgba(96, 165, 250, 1);
}

.CodeMirror-scroll {
  height: 100%;
}

.CodeMirror-cursors .CodeMirror-cursor {
  z-index: 10;
  border-right: 1px solid chartreuse;
}

.markdown {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h1 {
  text-align: left;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 1.2;
  margin-top: 2rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h3 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h4 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h5 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown h6 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown p {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-family: Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

.markdown img {
  height: auto;
  max-width: 100%;
  margin-bottom: 1rem;
  margin-top: .35rem;
}

.markdown img[title=float-left] {
  float: left;
  margin-left: 0;
  margin-right: 2rem;
}

.markdown img[title=float-right] {
  float: right;
  margin-left: 2rem;
  margin-right: 0;
}

pre {
  border-radius: 6px;
  overflow-x: auto;
  font-weight: 200;
  font-size: .8rem;
  color: initial;
  margin-bottom: 1rem;
}

pre pre {
  padding: 1rem !important;
}

.markdown code, .markdown pre {
  color: unset;
}

@media (max-width: 600px) {
  .markdown h1 {
    font-size: 2.5rem;
  }
  .markdown h2 {
    font-size: 2rem;
  }
  .markdown h3 {
    font-size: 1.2rem;
  }
  .markdown img {
    float: none;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
